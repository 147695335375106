import { Store } from 'pullstate';
import { APP_THEME } from '../models/Settings';
import { User } from '../types';

const USER_STORE_KEY = 'user';
const SETTINGS_STORE_KEY = 'settings';

export const UserStore = new Store({
    user: {} as User
});

UserStore.subscribe(
    s => s,
    userStore => {
        localStorage.setItem(USER_STORE_KEY, JSON.stringify(userStore));
    }
);

export const SettingsStore = new Store({
    theme: APP_THEME.AUTOMATIC
});

SettingsStore.subscribe(
    s => s,
    settingsStore => {
        localStorage.setItem(SETTINGS_STORE_KEY, JSON.stringify(settingsStore));
    }
);

export const setTheme = (theme: APP_THEME, save = true) => {
    const prefersDark = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)');
    const isThemeDark = (theme === APP_THEME.DARK) || (theme === APP_THEME.AUTOMATIC && prefersDark) as boolean;
    document.body.classList.toggle('dark', isThemeDark);

    if (!save) return;

    SettingsStore.update(s => {
        s.theme = theme;
    });
}

export const rehydrateSettingsStore = async () => {
    try {
        const settings = localStorage.getItem(SETTINGS_STORE_KEY);

        if (settings !== null) {
            const data = JSON.parse(settings);
            SettingsStore.replace(data);
            setTheme(data.theme, false);
        } else {
            SettingsStore.replace({ theme: APP_THEME.AUTOMATIC });
            setTheme(APP_THEME.AUTOMATIC, false);
        }

    } catch (e) {
        console.log('Error occured');
    }
}