import "./ExploreContainer.css";

interface ContainerProps {
  name: string;
  description: string;
  sprite?: string;
  spriteClass?: string;
  content?: any
}

const ExploreContainer: React.FC<ContainerProps> = ({
  name,
  description,
  sprite,
  spriteClass = '',
  content
}) => {
  return (
    <div className="container">
      {sprite && <img className={`sprite ion-margin ${spriteClass}`} src={sprite} alt="Sprite"></img>}
      <h4>{name}</h4>
      <p>{description}</p>
      {content}
    </div>
  );
};

export default ExploreContainer;
