import { getMode } from "@ionic/core";
import {
  IonButton,
  IonButtons,
  IonChip,
  IonContent,
  IonHeader,
  IonIcon,
  IonList,
  IonPage,
  IonSearchbar,
  IonTitle,
  IonToolbar,
  useIonModal
} from "@ionic/react";
import { OverlayEventDetail } from "@ionic/react/dist/types/components/react-component-lib/interfaces";
import { closeOutline, filterCircleOutline } from "ionicons/icons";
import debounce from "lodash/debounce";
import { useStoreState } from "pullstate";
import { useEffect, useRef, useState } from "react";
import ExploreContainer from "../components/ExploreContainer";
import GameItem from "../components/GameItem";
import LoadingGameItem from "../components/LoadingGameItem";
import PlatformChip from "../components/PlatformChip";
import { GameModel } from "../models/Game";
import { apiSearch } from "../stores/Api";
import { GameStore } from "../stores/Game";
import { updatePlatforms } from "../stores/Platform";
import PlatformFilter from "./PlatformFilter";

const Tab1: React.FC = () => {
  const ios = getMode() === "ios";
  const [searchText, setSearchText] = useState("");
  const [platforms, setPlatforms] = useState<number[]>([]);
  const [games, setGames] = useState<GameModel[]>([]);
  const [searching, setSearching] = useState(false);
  const searchInputEl = useRef<HTMLIonSearchbarElement>(null);

  const defaultPlatforms = useStoreState(GameStore, (s) => s.defaultPlatforms);

  useEffect(() => {
      updatePlatforms();
  }, []);

  useEffect(() => {
    fetchData(searchText);
  }, [platforms]);

  const getPlatforms = () => {
    if (platforms.length) {
      return platforms;
    } else if (defaultPlatforms.length) {
      return defaultPlatforms;
    }
    return [];
  };

  const removePlatform = (platformId: number) => {
    const updatedPlatforms = platforms.filter((id) => id !== platformId);
    setPlatforms(updatedPlatforms);
  };

  const fetchData = async (searchVal: string) => {
    if (searchVal === "") {
      return;
    }
    setSearching(true);

    const platformIds = getPlatforms();
    let response = await apiSearch(searchVal, platformIds);
    let queriedGames = response?.data;

    setSearching(false);
    setGames(queriedGames?.results);
  };

  const debouncedFetchData = debounce((e) => {
    setSearchText(e.target.value);
    fetchData(e.target.value);
  }, 500);

  const clearInput = () => {
    setSearchText("");
  };

  const onSearchButtonClick = () => {
    searchInputEl.current?.setFocus();
  };

  const [presentExplorePlatformFilter, dismiss] = useIonModal(PlatformFilter, {
    initialPlatforms: defaultPlatforms || [],
    onDismiss: (data: number, role: string) => dismiss(data, role),
  });

  function openExplorePlatformFilterModal() {
    presentExplorePlatformFilter({
      onWillDismiss: (ev: CustomEvent<OverlayEventDetail>) => {
        const platformIds = ev.detail.data;
        if (platformIds) {
          setPlatforms(platformIds);
        }
      },
    });
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonSearchbar
            onKeyUp={debouncedFetchData}
            onIonClear={clearInput}
            showCancelButton="focus"
            placeholder="Search by game name"
            ref={searchInputEl}
          ></IonSearchbar>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse={ios ? "condense" : undefined}>
          <IonToolbar>
            <IonTitle size="large">Explore</IonTitle>
            <IonButtons slot="end">
              <IonButton onClick={() => openExplorePlatformFilterModal()}>
                <IonIcon
                  slot="icon-only"
                  color="danger"
                  icon={filterCircleOutline}
                />
              </IonButton>
            </IonButtons>
          </IonToolbar>
          {platforms.length > 0 && (
            <IonToolbar>
              <div className="scroll-x pl-3">
                <>
                  <IonChip
                    onClick={() => setPlatforms([])}
                    outline
                    color="danger"
                    className="clear-button"
                  >
                    <IonIcon icon={closeOutline}></IonIcon>
                  </IonChip>
                  {platforms.map((platformId) => {
                    return (
                      <PlatformChip
                        platformId={platformId}
                        key={platformId}
                        handleClick={() => removePlatform(platformId)}
                      ></PlatformChip>
                    );
                  })}
                </>
              </div>
            </IonToolbar>
          )}
        </IonHeader>
        {searching && (
          <img
            className="sprite ion-margin"
            src="/assets/img/sonic_run.webp"
            alt="Loading"
          ></img>
        )}
        {searching &&
          [...Array(8)].map((e, i) => (
            <LoadingGameItem key={i}></LoadingGameItem>
          ))}
        {!searching && (
          <IonList>
            {games.map((game, index) => {
              return (
                game.platforms?.length && (
                  <GameItem
                    game={game}
                    key={game.id}
                    showSaved={true}
                  ></GameItem>
                )
              );
            })}
          </IonList>
        )}
        {games.length === 0 && searchText === "" && (
          <ExploreContainer
            sprite="/assets/img/sonic_lookup.webp"
            name="Find Your Games"
            description="Search for games by name/platform and tap them to add to your library."
            content={
              <IonButton onClick={() => onSearchButtonClick()}>
                Start Searching
              </IonButton>
            }
          />
        )}
        {games.length === 0 && !searching && searchText !== "" && (
          <ExploreContainer
            sprite="/assets/img/sonic_screech.webp"
            name="Search Over. Retry?"
            description="Sorry, but your game is in another castle. Please try and search again."
          />
        )}
      </IonContent>
    </IonPage>
  );
};

export default Tab1;
