import { IonChip } from "@ionic/react";
import { useStoreState } from "pullstate";
import { useEffect, useState } from "react";
import { PlatformModel } from "../models/Platform";
import { PlatformStore } from "../stores/Platform";

interface ContainerProps {
  platformId: number;
  selectedPlatformId?: number;
  handleClick?: any;
}

const PlatformChip: React.FC<ContainerProps> = ({
  platformId,
  selectedPlatformId,
  handleClick = () => {},
}) => {
  const [platform, setPlatform] = useState<PlatformModel>();
  const platforms = useStoreState(PlatformStore, (s) => s.platforms);

  useEffect(() => {
    let p = platforms.find((p) => p.id === platformId);
    setPlatform(p);
  }, [platformId]);

  return (
    <>
      {platform && (
        <IonChip
          onClick={() => handleClick(platformId)}
          outline={selectedPlatformId === platformId}
          color={selectedPlatformId === platformId ? "danger" : ""}
          style={{ order: platform?.generation }}
        >
          {platform?.abbreviation || platform?.name}
        </IonChip>
      )}
    </>
  );
};

export default PlatformChip;
