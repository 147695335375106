export const createJSONFile = (data: string, filename: string = "my_games.json") => {
    let body = document.body;
    const a = document.createElement("a");
    a.href = URL.createObjectURL(new Blob([data], {
        type: "application/json"
    }));
    a.setAttribute("download", filename);
    body.appendChild(a);
    a.click();
    body.removeChild(a);
}

export const readJSONFile = (callback: any) => {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'application/JSON';

    input.onchange = (e: any) => {
        const file = e.target.files[0];

        const reader = new FileReader();
        reader.readAsText(file, 'UTF-8');

        reader.onload = (readerEvent: any) => {
            const content = readerEvent.target.result;
            callback(content);
        }
    }

    input.click();
}

export const sortByName = (items: any[], property: string = "name") => {
    items.sort((a, b) => {
        const nameA = a[property]?.toUpperCase(); // ignore upper and lowercase
        const nameB = b[property]?.toUpperCase(); // ignore upper and lowercase
        if (nameA! < nameB!) {
            return -1;
        }
        if (nameA! > nameB!) {
            return 1;
        }

        // names must be equal
        return 0;
    });
}