import { getMode } from "@ionic/core";
import {
  IonChip,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonText,
  IonTitle, IonToolbar,
  useIonActionSheet,
  useIonAlert,
  useIonModal,
  useIonToast
} from "@ionic/react";
import { OverlayEventDetail } from "@ionic/react/dist/types/components/react-component-lib/interfaces";
import {
  downloadOutline,
  filterCircleOutline, heart, heartOutline, moonOutline,
  saveOutline, sunnyOutline,
  trashOutline
} from "ionicons/icons";
import { useStoreState } from "pullstate";
import { useMemo } from "react";
import { APP_THEME } from "../models/Settings";
import {
  GameStore,
  getGameStoreJSON,
  removeGameData,
  setDefaultPlatforms,
  setGameData
} from "../stores/Game";
import { setTheme, SettingsStore } from "../stores/User";
import { createJSONFile, readJSONFile } from "../stores/Utils";
import PlatformFilter from "./PlatformFilter";

const Tab3: React.FC = () => {
  const ios = getMode() === "ios";
  const [presentAlert] = useIonAlert();
  const [presentToast] = useIonToast();
  const [presentActionSheet] = useIonActionSheet();

  const library = useStoreState(GameStore, (s) => s.games);
  const defaultPlatforms = useStoreState(GameStore, (s) => s.defaultPlatforms);
  const appTheme = useStoreState(SettingsStore, (s) => s.theme);

  const [presentPlatformFilter, dismiss] = useIonModal(PlatformFilter, {
    platformIds: defaultPlatforms,
    onDismiss: (data: number, role: string) => dismiss(data, role),
  });

  const openPlatformFilterModal = () => {
    presentPlatformFilter({
      onWillDismiss: (ev: CustomEvent<OverlayEventDetail>) => {
        const platformIds = ev.detail.data;
        if (platformIds) {
          setDefaultPlatforms(platformIds);
          const toastMessage = platformIds.length
            ? "Platforms successfully saved"
            : "Platforms successfully reset";
          presentToast({
            duration: 2000,
            message: toastMessage,
          });
        }
      },
    });
  };

  const themeNames = ['Auto', 'Light', 'Dark'];
  const themeName = useMemo(() => {
    return themeNames[appTheme];
  }, [appTheme]);

  const onPresentThemeActionSheet = () => {
    presentActionSheet({
      buttons: [
        {
          text: "Automatic",
          handler: () => {
            setTheme(APP_THEME.AUTOMATIC);
          }
        },
        {
          text: "Light",
          handler: () => {
            setTheme(APP_THEME.LIGHT);
          }
        },
        {
          text: "Dark",
          handler: () => {
            setTheme(APP_THEME.DARK);
          }
        }, 
        {
          text: 'Cancel',
          role: 'cancel'
        }
      ],
    });
  };

  const onImportList = () => {
    presentAlert({
      header: "Overwrite your Library?",
      subHeader:
        "Importing a new Library will overwrite your existing. Are you sure you want to?",
      buttons: [
        {
          text: "Cancel",
          role: "cancel",
        },
        {
          text: "Yes, Import",
          role: "confirm",
          handler: () => {
            readJSONFile(importData);
          },
        },
      ],
    });
  };

  const importData = (data: any) => {
    setGameData(data, () => {
      presentToast({
        duration: 2000,
        message: "Library imported",
      });
    });
  };

  const onExportList = () => {
    const data = getGameStoreJSON() as string;
    createJSONFile(data);
  };

  const onClearData = () => {
    if (library.length === 0) {
      presentToast({
        duration: 1000,
        message: "There's nothing in your Library",
      });
      return;
    }

    let gamesText = library.length > 1 ? "games" : "game";
    presentAlert({
      header: "Clear your Library?",
      subHeader: `There's no coming back after this. Are you sure you want to clear ${library.length} ${gamesText} from your Library?`,
      buttons: [
        {
          text: "Cancel",
          role: "cancel",
        },
        {
          text: "Remove all",
          role: "confirm",
          handler: () => {
            removeGameData();
            presentToast({
              duration: 1000,
              message: "Your Library has been cleared",
            });
          },
        },
      ],
    });
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar></IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse={ios ? "condense" : undefined}>
          <IonToolbar>
            <IonTitle size="large">Settings</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonList>
          <IonItem button onClick={() => onPresentThemeActionSheet()}>
            <IonIcon
              slot="start"
              icon={appTheme === APP_THEME.LIGHT ? sunnyOutline : moonOutline}
            />
            <IonLabel>
              <h3>App Theme</h3>
              <p>Set the app theme.</p>
            </IonLabel>
            <IonChip color="danger" slot="end">{themeName}</IonChip>
          </IonItem>
          <IonItem button onClick={() => onImportList()}>
            <IonIcon slot="start" icon={downloadOutline} />
            <IonLabel className="ion-text-wrap">
              <h3>Import Games</h3>
              <p>Import a "my_games.json" file.</p>
            </IonLabel>
          </IonItem>
          <IonItem button onClick={() => onExportList()}>
            <IonIcon slot="start" icon={saveOutline} />
            <IonLabel className="ion-text-wrap">
              <h3>Export Games</h3>
              <p>Export your list of games to import on another device.</p>
            </IonLabel>
          </IonItem>
          <IonItem button onClick={() => openPlatformFilterModal()}>
            <IonIcon slot="start" icon={filterCircleOutline} />
            <IonLabel className="ion-text-wrap">
              <h3>Set Platforms</h3>
              <p>Choose the default platforms to filter your games by.</p>
            </IonLabel>
          </IonItem>
          <IonItem button onClick={() => onClearData()}>
            <IonIcon slot="start" icon={trashOutline} />
            <IonLabel className="ion-text-wrap">
              <IonText color="danger">
                <h3>Clear your Library</h3>
              </IonText>
              <p>Remove all games from your Library.</p>
            </IonLabel>
          </IonItem>
        </IonList>
        <p className="ion-text-center">Created with <IonIcon icon={heart} color="danger"></IonIcon> by<br/>Justin Alexander</p>
      </IonContent>
    </IonPage>
  );
};

export default Tab3;
