import axios from 'axios';

export const APIClient = axios.create({
    baseURL: `https://api.rawg.io/api/`
});

function url(prefix: string, suffix?: string) {
    let url = `${prefix}?key=${process.env.REACT_APP_API_KEY}`
    if (suffix) {
        url += `&${suffix}`;
    }
    return APIClient.get(url);
}

export const apiSearch = async (name: string, platformIds: number[]) => {
    try {
        let query = `search=${name}`;
        if (platformIds.length) {
            query += `&platforms=${platformIds.join()}`
        }
        return url('games', query)
    } catch (e) {

    }
}

export const apiPlatforms = async () => {
    try {
        return url('platforms', 'page_size=100');
    } catch (e) {
        
    }
}