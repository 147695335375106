import { IonBadge, IonIcon, IonItem, IonLabel, IonThumbnail } from "@ionic/react";
import { checkmarkCircle, imageOutline } from "ionicons/icons";
import { useStoreState } from "pullstate";
import { useEffect, useState } from "react";
import { GameModel } from "../models/Game";
import { GameStore, toggleGame } from "../stores/Game";
import { getSaved } from "../stores/Selectors";
import "./GameItem.css";
import PlatformLabel from "./PlatformLabel";

interface ContainerProps {
  game: GameModel;
  showSaved?: boolean;
  handleClick?: any;
  cardLayout?: boolean;
}

const GameItem: React.FC<ContainerProps> = ({
  game,
  showSaved,
  handleClick,
  cardLayout
}) => {
  const { id, name, platforms } = game;
  const [saved, setSaved] = useState<boolean>(false);
  const savedGames = useStoreState(GameStore, getSaved);

  useEffect(() => {
    processGameSaved(savedGames);
    GameStore.subscribe(
      (s) => s.games,
      (library) => {
        const savedIds: number[] = library.map((g: GameModel) => g.id!);
        processGameSaved(savedIds);
      }
    );
  }, []);

  const processGameSaved = (ids: number[]) => {
    const isSaved = ids.indexOf(id!) > -1;
    setSaved(isSaved);
  };

  const onGameSelected = () => {
    toggleGame(game);

    if (handleClick) {
      handleClick(game);
    }
  };

  return (
    <IonItem className="game-item" lines={cardLayout ? "none" : "inset"}>
      <IonThumbnail
        slot="start"
        onClick={() => onGameSelected()}
        className={!game.background_image ? "no-thumbnail" : ""}
      >
        {game.background_image && <img src={game.background_image} alt={name} />}
        {!game.background_image && <IonIcon icon={imageOutline}></IonIcon>}
      </IonThumbnail>
      <IonLabel onClick={() => onGameSelected()}>
        <h3>{name}</h3>
        <div className="mb-1"></div>
        <p>
          {game.platforms?.map((platform, index) => {
            return (
              <IonBadge key={platform.platform.id} className="mr-1">{platform.platform.name}</IonBadge>
            );
          })}
        </p>
      </IonLabel>
      {showSaved && saved && (
        <IonIcon
          className="mr-0"
          icon={checkmarkCircle}
          color="success"
          slot="end"
        ></IonIcon>
      )}
    </IonItem>
  );
};

export default GameItem;
