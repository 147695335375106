import {
  IonButton,
  IonButtons,
  IonContent,
  IonFooter,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonSearchbar,
  IonTitle,
  IonToolbar
} from "@ionic/react";
import { checkmarkCircle } from "ionicons/icons";
import { useStoreState } from "pullstate";
import { useEffect, useState } from "react";
import PlatformChip from "../components/PlatformChip";
import { PlatformModel } from "../models/Platform";
import { PlatformStore } from "../stores/Platform";

interface ContainerProps {
  onDismiss: any;
  platformIds: number[];
  initialPlatforms: number[];
}

const PlatformFilter: React.FC<ContainerProps> = ({
  onDismiss,
  platformIds = [],
  initialPlatforms = []
}) => {
  const [list, setList] = useState<PlatformModel[]>([]);
  const [searchText, setSearchText] = useState<string>("");
  const [selectedPlatforms, setSelectedPlatforms] = useState<number[]>([]);
  const platforms = useStoreState(PlatformStore, (s) => s.platforms);

  useEffect(() => {
    setSelectedPlatforms(platformIds);
  }, []);

  useEffect(() => {
    handleFilter(searchText);
  }, [platforms]);

  const onSelectPlatform = (platformId: number) => {
    const platformIndex = selectedPlatforms.indexOf(platformId);
    if (platformIndex > -1) {
      setSelectedPlatforms(selectedPlatforms.filter((p) => p !== platformId));
    } else {
      const updatedPlatforms = [...selectedPlatforms, platformId];
      setSelectedPlatforms(updatedPlatforms);
    }
  };

  const onResetPlatforms = () => {
    onDismiss([]);
  };

  const onSavePlatforms = () => {
    onDismiss(selectedPlatforms);
  };

  const handleFilter = (query: string) => {
    if (query === "" || null) {
      clearInput();
    }

    const text = query.toLowerCase();
    setSearchText(text);

    const selectablePlatforms = initialPlatforms.length > 1 ? platforms.filter(p => initialPlatforms.includes(p.id)) : platforms;

    // filter by search term
    const results = [...selectablePlatforms].filter((platform: PlatformModel) => {
      const name = platform.name?.toLowerCase();
      return name!.includes(text);
    });

    setList(results);
  };

  const clearInput = () => {
    setSearchText("");
    setList(platforms);
  };

  return (
    <IonPage>
      <IonHeader translucent={true}>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton onClick={() => onDismiss()}>Cancel</IonButton>
          </IonButtons>
          <IonTitle>Select</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={() => onResetPlatforms()}>Reset</IonButton>
          </IonButtons>
        </IonToolbar>
        <IonToolbar>
          <IonSearchbar
            value={searchText}
            onIonChange={(e) => handleFilter(e.detail.value!)}
            onIonClear={clearInput}
            showCancelButton="focus"
            placeholder="Search by platform name"
          ></IonSearchbar>
        </IonToolbar>
        {selectedPlatforms.length > 0 && (
          <IonToolbar>
            <div className="scroll-x pl-0">
              {selectedPlatforms.map((platformId) => {
                return (
                  <PlatformChip
                    platformId={platformId}
                    key={platformId}
                    handleClick={onSelectPlatform}
                  ></PlatformChip>
                );
              })}
            </div>
          </IonToolbar>
        )}
      </IonHeader>
      <IonContent fullscreen>
        <IonList>
          {list.map((platform, index) => {
            return (
              <IonItem
                key={platform.id}
                onClick={() => onSelectPlatform(platform.id)}
              >
                <IonLabel>
                  {platform.name}
                </IonLabel>
                {selectedPlatforms.includes(platform.id) && (
                  <IonIcon
                    className="mr-0"
                    icon={checkmarkCircle}
                    color="success"
                    slot="end"
                  ></IonIcon>
                )}
              </IonItem>
            );
          })}
        </IonList>
      </IonContent>
      <IonFooter>
        <IonToolbar>
          <IonButton
            expand="block"
            color="success"
            onClick={() => onSavePlatforms()}
          >
            Set Platforms ({selectedPlatforms.length})
          </IonButton>
        </IonToolbar>
      </IonFooter>
    </IonPage>
  );
};

export default PlatformFilter;
