import {
    IonItem,
    IonLabel,
    IonSkeletonText,
    IonThumbnail
} from "@ionic/react";

const LoadingGameItem: React.FC = () => {
  return (
    <IonItem>
      <IonThumbnail slot="start">
        <IonSkeletonText animated />
      </IonThumbnail>
      <IonLabel>
        <h3>
          <IonSkeletonText animated style={{ width: "50%" }} />
        </h3>
        <p>
          <IonSkeletonText animated style={{ width: "80%" }} />
        </p>
      </IonLabel>
    </IonItem>
  );
};

export default LoadingGameItem;
