import { Store } from 'pullstate';
import { PlatformModel } from '../models/Platform';
import { apiPlatforms } from './Api';
import orderBy from 'lodash/orderBy';

export const PlatformStore = new Store({
    platforms: [] as PlatformModel[]
});

export const updatePlatforms = () => {
    apiPlatforms().then((res) => {
        PlatformStore.update(s => {
            let platforms = orderBy(res?.data.results, ['name']);
            s.platforms = platforms;
        })
    });
};

PlatformStore.subscribe(
    s => s,
    platformStore => {
        localStorage.setItem('platforms', JSON.stringify(platformStore));
    }
);

export const rehydratePlatformStore = async () => {
    try {
        const platforms = localStorage.getItem('platforms');

        if (platforms !== null) {
            const data = JSON.parse(platforms);
            PlatformStore.replace(data);
        }

    } catch (e) {
        console.log('Error occured');
    }
};