import {
  IonApp,
  IonIcon,
  IonLabel,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
  setupIonicReact
} from "@ionic/react";
import { IonReactHashRouter } from "@ionic/react-router";
import { albumsOutline, cogOutline, searchOutline } from "ionicons/icons";
import { Redirect, Route } from "react-router-dom";
import Tab1 from "./pages/Explore";
import Tab2 from "./pages/Library";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/display.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/padding.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";

/* Theme variables */
import { useEffect } from "react";
import Tab3 from "./pages/Settings";
import { rehydrateGameStore } from "./stores/Game";
import { rehydratePlatformStore } from "./stores/Platform";
import { rehydrateSettingsStore } from "./stores/User";
import "./theme/app.scss";
import "./theme/variables.css";

setupIonicReact();

const App: React.FC = () => {

  useEffect(() => {
    rehydrateGameStore();
    rehydratePlatformStore();
    rehydrateSettingsStore();
  }, []);

  return (
    <IonApp>
      <IonReactHashRouter>
        <IonTabs>
          <IonRouterOutlet>
            <Route exact path="/explore">
              <Tab1 />
            </Route>
            <Route exact path="/library">
              <Tab2 />
            </Route>
            <Route exact path="/settings">
              <Tab3 />
            </Route>
            <Route exact path="/">
              <Redirect to="/library" />
            </Route>
          </IonRouterOutlet>
          <IonTabBar slot="bottom">
            <IonTabButton tab="tab1" href="/explore">
              <IonIcon icon={searchOutline} />
              <IonLabel>Explore</IonLabel>
            </IonTabButton>
            <IonTabButton tab="tab2" href="/library">
              <IonIcon icon={albumsOutline} />
              <IonLabel>Library</IonLabel>
            </IonTabButton>
            <IonTabButton tab="tab3" href="/settings">
              <IonIcon icon={cogOutline} />
              <IonLabel>Settings</IonLabel>
            </IonTabButton>
          </IonTabBar>
        </IonTabs>
      </IonReactHashRouter>
    </IonApp>
  );
};

export default App;
