import { getMode } from "@ionic/core";
import {
  IonButton,
  IonButtons,
  IonChip,
  IonContent,
  IonHeader,
  IonIcon,
  IonList,
  IonPage,
  IonSearchbar,
  IonTitle,
  IonToolbar,
  useIonToast,
} from "@ionic/react";
import { closeOutline, grid, listOutline } from "ionicons/icons";
import { useStoreState } from "pullstate";
import { useEffect, useState } from "react";
import ExploreContainer from "../components/ExploreContainer";
import GameItem from "../components/GameItem";
import PlatformChip from "../components/PlatformChip";
import { GameModel } from "../models/Game";
import { GameStore, toggleGame } from "../stores/Game";
import "./Library.css";

const Tab2: React.FC = () => {
  const ios = getMode() === "ios";
  const [present] = useIonToast();
  const [searchText, setSearchText] = useState("");
  const [list, setList] = useState<GameModel[]>([]);
  const [platform, setPlatform] = useState<number>(-1);
  const [platformList, setPlatformList] = useState<number[]>([]);
  const [cardLayout, setCardLayout] = useState<boolean>(false);

  const library = useStoreState(GameStore, (s) => s.games);
  const libraryPlatforms = useStoreState(GameStore, (s) => s.platforms);
  const defaultPlatforms = useStoreState(GameStore, (s) => s.defaultPlatforms);

  useEffect(() => {
    handleFilter(searchText);
  }, [library, platform]);

  useEffect(() => {
    const platforms = defaultPlatforms || [];
    if (platforms.length) {
      setPlatformList(defaultPlatforms);
    } else {
      setPlatformList(libraryPlatforms);
    }
  }, [defaultPlatforms, libraryPlatforms]);

  const onGameSelected = (game: GameModel) => {
    present({
      buttons: [{ text: "Undo", handler: () => toggleGame(game) }],
      duration: 1000,
      message: "Removed from Library",
    });
  };

  const handleFilter = (query: string) => {
    if (query == "" || null) {
      clearInput();
    }

    let results: GameModel[] = [];
    // filter by platform
    if (platform >= 0) {
      results = library.filter((game: GameModel) => {
        if (game.platforms) {
          return game.platforms.find(p => p.platform.id === platform);
        }
        return false;
      });
    } else {
      results = [...library];
    }

    const text = query.toLowerCase();
    setSearchText(text);

    // filter by search term
    results = results.filter((game: GameModel) => {
      const name = game.name?.toLowerCase();
      return name!.includes(text);
    });

    setList(results);
  };

  const clearInput = () => {
    setSearchText("");
    setList(library);
  };

  const clearPlatform = () => {
    setPlatform(-1);
  };

  const onSelectPlatform = (platformId: number) => {
    if (platformId === platform) {
      clearPlatform();
    } else {
      setPlatform(platformId);
    }
  };

  const onToggleCardLayout = () => {
    setCardLayout(!cardLayout);
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonSearchbar
            value={searchText}
            onIonChange={(e) => handleFilter(e.detail.value!)}
            onIonClear={clearInput}
            showCancelButton="focus"
            placeholder="Search by game name"
          ></IonSearchbar>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse={ios ? "condense" : undefined}>
          <IonToolbar>
            <IonTitle size="large">
              Library {list?.length > 0 && <small>({list.length})</small>}
            </IonTitle>
            <IonButtons slot="end">
              <IonButton
                disabled={!list?.length}
                onClick={() => onToggleCardLayout()}
              >
                <IonIcon
                  slot="icon-only"
                  icon={cardLayout ? listOutline : grid}
                  color="danger"
                />
              </IonButton>
            </IonButtons>
          </IonToolbar>
          <IonToolbar>
            <div className="scroll-x mb-1">
              {platform > -1 && (
                <IonChip
                  onClick={clearPlatform}
                  outline
                  color="danger"
                  className="clear-button"
                >
                  <IonIcon icon={closeOutline}></IonIcon>
                </IonChip>
              )}
              {platformList.map((platformId) => {
                return (
                  (platformId === platform || platform === -1) && (
                    <PlatformChip
                      platformId={platformId}
                      selectedPlatformId={platform!}
                      key={platformId}
                      handleClick={onSelectPlatform}
                    ></PlatformChip>
                  )
                );
              })}
            </div>
          </IonToolbar>
        </IonHeader>
        <IonList className={cardLayout ? "game-cards" : ""}>
          {list.map((game, index) => {
            return (
              <GameItem
                game={game}
                key={game.id}
                handleClick={onGameSelected}
                cardLayout={cardLayout}
              ></GameItem>
            );
          })}
        </IonList>
        {list.length === 0 && (
          <>
            <ExploreContainer
              sprite="/assets/img/sonic_idle.webp"
              spriteClass="flip"
              name="Nuthin' 'ere chief!"
              description={
                platform !== -1 || searchText.length
                  ? "There isn't anything here matching your filters."
                  : "It's looking empty here. Explore to add games to your Library."
              }
              content={<IonButton href="/#/explore">Add Games</IonButton>}
            />
          </>
        )}
      </IonContent>
    </IonPage>
  );
};

export default Tab2;
